import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilSpeedometer, cilGroup, cilAddressBook, cilBellExclamation,
  cilCode, cilNoteAdd, cilReportSlash, cilDelete, cilLibraryAdd, cilExposure, cilInfo, cilCash, cilAlarm, cilLifeRing, cilListFilter, cibAddthis, cilWallet,
  cilClipboard,
  cilBug,
  cilHealing,
  cilScreenDesktop,
  cilBasket
} from '@coreui/icons'
import { CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
  },
  {
    component: CNavTitle,
    name: 'App Data',
  },
  {
    component: CNavItem,
    name: 'App Users',
    to: '/users',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Jobs',
    to: '/jobs',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Generate Secret Code',
    to: '/secretcode',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilCode} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Create Promo Code',
    to: '/promocode',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilLifeRing} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Promo Code Details',
    to: '/promocodedetail',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilListFilter} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Create Affiliate',
    to: '/createAffiliate',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilHealing} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Affiliate details',
    to: '/affiliateDetails',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilScreenDesktop} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Withdrawal Requests',
    to: '/allPendingWithDrawRequests',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
    // badge: { color: 'danger', text: counter },
  },
  {
    component: CNavItem,
    name: 'Add Profession',
    to: '/addprofession',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilNoteAdd} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Profession Details',
    to: '/professiondetail',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilInfo} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Reported Users',
    to: '/ReportUsers',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilReportSlash} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Reported Posts',
    to: '/ReportPosts',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilDelete} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Add Promotions',
    to: '/AddPromotions',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilLibraryAdd} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Promotion Details',
    to: '/PromotionDetail',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilExposure} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Send Notifications',
    to: '/sendNotifications',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilAlarm} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Admin Data',
    roles: ['Super_Admin'],
  },
  {
    component: CNavItem,
    name: 'Dashboard Users',
    to: '/dashboardUsers',
    roles: ['Super_Admin'],
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Payments',
    to: '/usersPayments',
    roles: ['Super_Admin'],
    icon: <CIcon icon={cilCash} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Notifications',
    to: '/Notification',
    roles: ['Super_Admin'],
    icon: <CIcon icon={cilBellExclamation} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Companies Data',
    roles: ['Super_Admin'],
  },
  {
    component: CNavItem,
    name: 'Register New Company',
    to: '/RegisterCompany',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cibAddthis} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Companies Details',
    to: '/CompanyDetails',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilWallet} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Reported Posts',
    to: '/ReportedCompanyPosts',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Reported Users',
    to: '/reportedCompanyUsers',
    roles: ['Super_Admin', 'Admin', 'Manager', 'Employee'],
    icon: <CIcon icon={cilBug} customClassName="nav-icon" />,
  },
]

export default _nav
